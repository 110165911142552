@import '../../standard_styles/padding';

.delete-phrase-group {
  .delete-phrase-btn {
    &~.phrase-container, &~span {
      transition: all .6s;
    }
    &:hover{
      &~.phrase-container, &~span {
        opacity: 0.5;
      }
      opacity: 1;
    }
    &~span {
      opacity: 0;
      top: 0.25em;
      white-space: nowrap;
      pointer-events: none;
      width: 5em;
    }
    opacity: 0.5;
    transition: opacity .6s;
    height: 2em;
    width: 2em;
    cursor: pointer;
  }
}

.add-phrase-btn {
  &:hover{
    opacity: 1;
    &~span {
      opacity: 0.5;
    }
  }
  &~span {
    transition: opacity .6s;
    opacity: 0;
  }
  margin-right: 5px;
  opacity: 0.5;
  transition: opacity .6s;
  height: 2em;
  width: 2em;
  cursor: pointer;
}