@import '../../standard_styles/padding.scss';
@import '../../standard_styles/margin.scss';
@import '../../standard_styles/breakpoints.scss';
$sheet-height-width-ratio: 1.294; // height:width of a standard 8.5"/11" printer paper

$sheet-width: calc(100vw - #{$large-margin} * 2 - #{$large-padding} * 2);
$sheet-height: calc(#{$sheet-width} * #{$sheet-height-width-ratio});

.sheet-container {
  @media print {
    box-shadow: none;
  }
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
  margin: $large-margin;
  padding: $large-padding;
  width: $sheet-width;
  height: $sheet-height;
  background-color: white;
}

.delete-line-group {
  &~.line-container {
    transition: opacity .6s;
  }
  &:hover{
    ~.line-container {
      opacity: 0.3;
    }
    opacity: 1;
    span {
      opacity: 1;
    }
  }
  span {
    position: absolute;
    left: 3.25em;
    top: 1em;
    pointer-events: none;
    font-size: 1.5em;
    opacity: 0;
    transition: opacity .6s;
  }
  > img {
    width: 5em;
    height: 5em;
  }
  opacity: 0.5;
  transition: opacity .6s;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}

.add-line-btn {
  &:hover{
    opacity: 1;
    &~span {
      opacity: 1;
    }
  }
  &~span {
    transition: opacity .6s;
    opacity: 0;
    font-size: 1.25em;
    margin-left: 5px;
  }
  opacity: 0.5;
  transition: opacity .6s;
  height: 5em;
  width: 5em;
  cursor: pointer;
}