.phrase-container {
  border-radius: 10px;
  padding: 3px;
  cursor: pointer;
  transition: background-color .6s;
  background-color: white;
  min-width: 5em;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
}

.lyric-input {
  // default width of the chord selector
  min-width: 100%;
  padding: 8px 0px;
  font-size: 1.5em;
  border-radius: 7px;
  outline: none;
  border: none;
}