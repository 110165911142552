@import './standard_styles/margin.scss';
@import './standard_styles/padding.scss';
@import './standard_styles/alignment.scss';
@import './standard_styles/print.scss';
@import './standard_styles/font-size.scss';

.save-btn, .open-btn {
  cursor: pointer;
  width: 3em;
  height: 3em;
  opacity: 0.5;
  transition: opacity, background-color .6s;
  background-color: transparent;
  padding: $standard-padding;
  border-radius: 10px;
  &:hover {
    opacity: 1;
    background-color: rgba(0, 128, 128, 0.8);
  }
}

.add-sheet-btn {
  width: 5em;
  height: 5em;
}

.title-input {
  font-size: 2.5em;
  outline: none;
  text-align: center;
  border-radius: 10px;
  margin: $standard-margin;
  padding: $standard-padding;
  background-color: transparent;
  transition: background-color .6s;
  color: white;
  border: none;
  min-width: 7em;
  &:hover {
    background-color: teal;
  }
}

@keyframes backgroundColorShift {
  0% { background-color: lightgreen; }
  50% { background-color: lightseagreen; }
  100% { background-color: lightblue; }
}

.sheet-selectors {
  > img {
    cursor: pointer;
    transition: opacity .6s;
    opacity: .5;
    &:hover {
      opacity: 1;
      &~span {
        opacity: 1;
      }
    }
    &~span {
      position: absolute;
      pointer-events: none;
      right: -5em;
      transition: opacity .6s;
      font-size: 1.25em;
      opacity: 0;
      margin-left: 3px;
    }
  }
  > span.sheet-selector {
    cursor: pointer;
    transition: background-color .6s;
    background-color: darkgrey;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 2em;
    font-size: 1.5em;
    padding: $standard-padding;
    margin: $standard-margin;
    width: 2em;
    height: 2em;
    color: white;
    &:hover {
      background-color: rgb(104, 118, 129);
    }
  }
  margin: $standard-margin;
  position: relative;
}

.app-container {
  animation-duration: 30s;
  animation-name: backgroundColorShift;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
  min-height: 100vh;
}


.io-row {
  margin: $standard-margin;
  display: flex;
  flex-direction: row;
}