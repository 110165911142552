@import './breakpoints.scss';

$standard-font-size: 16px;

body, input {
    font-size: $standard-font-size / 1.5;
}

@media screen and (min-width: $sm) {
    body, input {
        font-size: $standard-font-size / 1.25;
    }
}

@media screen and (min-width: $md) {
    body, input {
        font-size: $standard-font-size;
    }
}

@media screen and (min-width: $lg) {
    body, input {
        font-size: $standard-font-size * 1.25;
    }
}

@media screen and (min-width: $xl) {
    body, input {
        font-size: $standard-font-size * 1.5;
    }
}
