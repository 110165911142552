html {
  min-height: 100vh;
  overflow-x: hidden;
}
body {
  margin: 0;
  min-height: 100vh;
  width: 100%;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 200;
}

#root {
  min-height: 100vh;
}