@import '../../standard_styles/margin.scss';

$root-circle-size: 5em;

.chord-container {
  height: 6em;
}

.selector-container {
  position: absolute;
  transition: transform .6s;
}

.fretboard {
  pointer-events: none;
  width: 10em;
}

.selector-buttons {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: all .6s;
  color: rgba(0,128,128,1);
  cursor: pointer;
  background-color: rgb(255,255,255);
  &:hover, &.selected {
    background-color: rgb(0,128,128);
    color: white;
  }
}

.chord {
  position: absolute;
  cursor: pointer;
  height: $root-circle-size;
  width: $root-circle-size;
  background-color: teal;
  color: white;
  border-radius: 50%;
  transition: transform 0.6s;
  z-index: 4;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1)
  }
}

@mixin generate-selector-styles($scale) {
  position: absolute;
  transition: all 0.6s;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  &.show {
    transform: scale($scale);
    opacity: 1;
    pointer-events: unset;
  }
}

/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size, $circle-scale) {
  width:  $circle-size;
  height: $circle-size;
  border-radius: 50%;

  > * {
    position: absolute;
    top:  50%;
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform:
          rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg)
          scale(1 / $circle-scale);
      }

      $rot: $rot + $angle;
    }
  }
}

.interval-selector, .step-selector, .root-note-selector, .added-tones-selector {
  span {
    @extend .selector-buttons;
  }
}

.root-note-selector {
  $scale: 1.8;
  z-index: 3;
  background-color: purple;
  @include generate-selector-styles($scale: $scale);
  @include on-circle($item-count: 7, $circle-size: $root-circle-size, $item-size: 3.25em, $circle-scale: $scale);
}

.step-selector {
  $scale: 3.3;
  z-index: 2;
  background-color: orange;
  @include generate-selector-styles($scale: $scale);
  @include on-circle($item-count: 3, $circle-size: $root-circle-size, $item-size: 3.25em, $circle-scale: $scale);
}

.interval-selector {
  $scale: 4.8;
  z-index: 1;
  background-color: brown;
  @include generate-selector-styles($scale: $scale);
  @include on-circle($item-count: 5, $circle-size: $root-circle-size, $item-size: 3.25em, $circle-scale: $scale);
}

.added-tones-selector {
  $scale: 6.3;
  z-index: 1;
  background-color: lightblue;
  @include generate-selector-styles($scale: $scale);
  @include on-circle($item-count: 11, $circle-size: $root-circle-size, $item-size: 3.25em, $circle-scale: $scale);
}