@media print {
    .print {
        visibility: visible;
    }
}

@media print {
    .noprint {
        position: absolute;
        visibility: hidden;
    }
}
