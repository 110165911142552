.wrap {
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-direction: row;
  &.center {
    align-items: center;
    &.top {
      justify-content: flex-start;
    }
    &.bottom {
      justify-content: flex-end;
    }
    &.center {
      &-squished {
        justify-content: space-around;
      }
      &-spread {
        justify-content: space-between;
      }
      &-spaced {
        justify-content: space-evenly;
      }
    }
  }
  &.left {
    align-items: flex-start;
    &.center {
      &-squished {
        justify-content: space-around;
      }
      &-spread {
        justify-content: space-between;
      }
      &-spaced {
        justify-content: space-evenly;
      }
    }
    &.top {
      justify-content: flex-start;
    }
    &.bottom {
      justify-content: flex-end;
    }
  }
  &.right {
    align-items: flex-end;
    &.center {
      &-squished {
        justify-content: space-around;
      }
      &-spread {
        justify-content: space-between;
      }
      &-spaced {
        justify-content: space-evenly;
      }
    }
    &.top {
      justify-content: flex-start;
    }
    &.bottom {
      justify-content: flex-end;
    }
  }
}

.column {
  @extend .row;
  flex-direction: column;
}
